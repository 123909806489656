<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" @keyup.enter.native="goodsSearch" placeholder="标签名称" style="width: 200px;;margin-right: 10px;"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<buttonPermissions :datas="'addLabel'">
					<el-button type="primary" style="margin-left:15px;width:90px;" @click="goodsAdd({})">添加</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="MemberTagData" style="width: 100%;" v-loading="loading">
				<el-table-column label="标签名称" width="350px">
					<template slot-scope="scope">
						<div style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;width: 300px;">{{scope.row.MemberTagName}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="TagTypeValue" label="标签类型"></el-table-column>
				<el-table-column label="标签客户">
					<template slot-scope="scope">
						<div v-if="scope.row.MemberCount==0">{{scope.row.MemberCount}}</div>
						<div v-else style="color: #409EFF;cursor: pointer;" @click="tagDoorLink(scope.row)">{{scope.row.MemberCount}}</div>
					</template>
				</el-table-column>
				<el-table-column label="打标条件" width="500">
					<template slot-scope="scope">
						<div v-if="scope.row.MarkingCondition">{{scope.row.MarkingCondition}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间" width="155"></el-table-column>
				<el-table-column label="操作" width="160">
					<template slot-scope="scope">
						<buttonPermissions :datas="'editLabel'">
							<el-button type="text" @click="goodsAdd(scope.row)">编辑</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'deleteLabel'">
							<el-button type="text" @click="deleteChose(scope.row)" style="color:#f00;margin-left:15px">删除</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 编辑客户标签的弹框 -->
		<el-dialog :visible.sync="editLabelVisable" width="40%" :title="editLabelTitle" :show-close="false">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="标签名称" prop="MemberTagName">
						<el-input v-model="ruleForm.MemberTagName" placeholder="最多输入16个汉字" maxlength="16" style="width:240px;"></el-input>
					</el-form-item>

					<el-form-item label="标签类型" prop="TagType">
						<el-radio-group v-model="ruleForm.TagType" :disabled="SaveId>0">
							<div>
								<el-radio :label="0">手动标签</el-radio>
							</div>
							<div style="margin-top: 15px;">
								<el-radio :label="1">自动标签</el-radio>
							</div>
						</el-radio-group>
					</el-form-item>

					<el-form-item v-if="ruleForm.TagType ===1" label="打标条件" required>
						<div style="display: inline-block;margin-right:8px;">最近一次消费时间</div>
						<div style="display: inline-block;">
							<!-- <label class="label">变更时间: </label> -->
							<el-date-picker v-model="timeValue" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 range-separator="~" start-placeholder="开始时间" end-placeholder="结束时间" @change="TimeChange" :disabled="SaveId>0"
							 :picker-options="CustomizeOptionsDate">
							</el-date-picker>
							<div style="color:red;" v-if="TimeError">请选择变更时间</div>

						</div>
					</el-form-item>
				</el-form>

				<div style="text-align: center;margin-top:30px;">
					<el-button style="margin-right:30px;width: 120px;" @click="editLabelVisable=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;" @click="saveChange('ruleForm')" :loading="saveLoading">保存</el-button>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		GetmemberTaglist,
		SaveMembertags,
		MemberTagdelete
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				// - 24 * 60 * 60 * 1000
				CustomizeOptionsDate: {
					disabledDate(time) {
						return (
							time.getTime() < Date.now() - 24 * 60 * 60 * 1000
						)
					}
				},
				searchKey: '',
				MemberTagData: [],
				currentPage: 1,
				pageSize: 10,
				Total: 1,
				editLabelVisable: false,
				editLabelTitle: '添加标签',
				timeValue: [],
				ruleForm: {
					MemberTagName: '',
					TagType: '',
				},
				rules: {
					MemberTagName: [{
							required: true,
							message: '请输入标签名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 16,
							message: '最多输入16个字',
							trigger: 'blur'
						}
					],
					TagType: [{
						required: true,
						message: '请选择标签类型',
						trigger: 'change'
					}],
				},
				loading: false,
				saveLoading: false,
				SaveId: 0,
				TimeError: false,

			};
		},
		created() {
			this.GetMemtaglist()

		},
		beforeMount() {

		},
		methods: {
			//标签跳转
			tagDoorLink(record) {
				this.$router.push({
					path: '/Customer/CustomerSearch',
					query: {
						customerTagId: record.Id
					}
				});
			},
			TimeChange() {
				if (this.timeValue.length) {
					this.TimeError = false
				}
			},
			//获取客户标签列表
			async GetMemtaglist() {
				this.loading = true
				try {
					let data = {
						KeyWord: this.searchKey,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await GetmemberTaglist(data)
					this.MemberTagData = result.Result.Results || [];
					this.Total = result.Result.Total;
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.GetMemtaglist();
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.GetMemtaglist();
			},
			goodsSearch() {
				this.currentPage = 1
				this.GetMemtaglist();
			},
			goodsAdd(record) {
				this.editLabelVisable = true
				this.SaveId = record.Id ? record.Id : 0

				//清除验证
				this.$nextTick(function() {
					this.$refs.ruleForm.clearValidate()
				})
				//编辑
				if (record.Id > 0) {
					this.editLabelTitle = '编辑标签'
					this.ruleForm.MemberTagName = record.MemberTagName
					this.ruleForm.TagType = record.TagType
					if (record.TagType == 1) {
						this.timeValue = [record.StartTime, record.EndTime]
					}
				} else {
					this.ruleForm.MemberTagName = ''
					this.ruleForm.TagType = 0
					this.timeValue = []
				}

			},
			deleteChose(row) {
				this.$confirm('是否确认将该标签删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let result = await MemberTagdelete({
						Id: row.Id
					});
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功！'
						});
						this.currentPage = 1;
						this.GetMemtaglist();
					} else {
						this.$message({showClose: true,
							type: 'error',
							message: result.Message
						});
					}
				})
			},
			saveChange(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.saveLoading = true
						try {
							//特殊判断
							if (this.ruleForm.TagType == 1) {
								if (this.timeValue.length == 0) {
									this.TimeError = true
									return false
								}
							}
							let data = {
								Id: this.SaveId,
								MemberTagName: this.ruleForm.MemberTagName,
								TagType: this.ruleForm.TagType
							}
							if (this.ruleForm.TagType == 1) {
								data.StartTime = this.timeValue[0]
								data.EndTime = this.timeValue[1]
							}

							let result = await SaveMembertags(data)
							if (result.IsSuccess) {
								this.$message({showClose: true,
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editLabelVisable = false;
								this.GetMemtaglist()
							}
						} catch (err) {

						} finally {
							this.saveLoading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},
		}
	}
</script>

<style lang="less" scoped>
</style>
